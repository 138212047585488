<template>
  <div class="testimonials">
    <div class="container">
      <div class="row p-relative">
        <div class="testimonials__title">
          <span>Testimonials</span>
        </div>
      </div>
    </div>
    <div class="row" v-if="testimonials && testimonials.data.length">

      <u-animate-container>
        <u-animate
            name="fadeIn"
            delay="0s"
            duration="1s"
            :iteration="1"
            :offset="0"
            animateClass="animate__fadeIn"
            :begin="false"
        >
          <VueSlickCarousel class="testimonials__slider" :arrows="true" :dots="true" v-bind="sliderSetting">
            <div class="testimonials__slide" v-for="(item, index) in testimonials.data" :key="index">
              <div class="testimonials__item">
                <div class="testimonials__item-title">
                  {{item.name}}
                </div>
                <div class="testimonials__item-date">
                  {{item.created_at}}
                </div>
                <div class="testimonials__item-text">
                  {{item.message}}
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </u-animate>
      </u-animate-container>
    </div>
    <div class="d-flex justify-center">
      <button class="btn yellow" @click="showForm = true">Leave Feedback</button>
    </div>
    <transition name="form-show">
      <div class="testimonials__form" v-if="showForm">
      <div class="testimonials__form-block">
        <div class="testimonials__form-close" @click="showForm = false"/>
        <div class="testimonials__form-title">
          leave feedback
        </div>
        <label class="input-block col-md-4">
          <input type="text" v-model="$v.form.name.$model"><span/>
          <span class="input-block__name" :class="form.name.length ?'active' : null" >Your Name</span>
          <span class="input-block__error" v-if="$v.form.name.$dirty && !$v.form.name.required">name is required</span>
        </label>
        <label class="input-block col-md-8">
          <input type="text" v-model="$v.form.message.$model"><span/>
          <span class="input-block__name" :class="form.message.length ?'active' : null" >Message</span>
          <span class="input-block__error" v-if="$v.form.message.$dirty && !$v.form.message.required">message is required</span>
        </label>
        <button
            class="btn yellow mt-3"
            @click="sendForm()"
            :class="$v.form.$dirty && !$v.form.$invalid ? null : 'error'"
        >
          Leave Feedback
        </button>
      </div>
    </div>
    </transition>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapActions, mapGetters, mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";
export default {
  components: { VueSlickCarousel },
  data(){
    return{
      showForm:false,
      sliderSetting:[
        {
          "dots": true,
          "infinite": true,
          "speed": 500,
          "slidesToShow": 5,
          "centerMode": true,
          "centerPadding": "0",
          focusOnSelect: true,
          "slidesToScroll": 5,
          "initialSlide": 0,
          "responsive": [
            {
              "breakpoint": 1620,
              "settings": {
                "slidesToShow": 4,
                "slidesToScroll": 4,
                "infinite": true,
                "dots": true
              }
            },
            {
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "infinite": true,
                "dots": true
              }
            },
            {
              "breakpoint": 600,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "initialSlide": 2
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
              }
            }
          ]
        }
      ],
      form:{
        name:'',
        message:'',
      }
    }
  },

  validations: {
    form: {
      name: {
        required,
      },
      message: {
        required,
      },
    }
  },
  computed:{
    ...mapGetters({
      testimonials:'landing/testimonials'
    })
  },
  methods:{
    sendForm(){
      this.$v.$touch()
      if(!this.$v.form.$error){
        this.showForm = false;
        this.sendTestimonial(this.form).then(res => {
          if(res.status){
            this.toggleAlertPopup({
              status: true,
              content:{
                title:'Success!',
                text:'Your message has been successfully delivered. Wait for a reply to the email!',
                type:"success"
              }
            })
          }else{
            this.toggleAlertPopup({
              status: true,
              content:{ title:'Error', text: res.data.errors, type:"Error!"}
            })
          }
          this.clearForm();
        })
      }
    },
    clearForm(){
      this.form = {
        name: '',
        message: ''
      }
    },
    ...mapActions({
      sendTestimonial:'landing/sendTestimonial'
    }),
    ...mapMutations({
      toggleAlertPopup: 'general/toggleAlertPopup'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.testimonials{
  padding: 85px 0 50px;
  background:linear-gradient(to bottom, white 0,white 50%, $grey 50%);
  position: relative;
  @media screen and (max-width: 720px){
    padding: 40px 20px;
  }
  &__title{
    font-family: 'Mont', Arial;
    font-size: 96px;
    font-weight: 900;
    color: $grey;
    margin-bottom: 60px;
    @media screen and (max-width: 1220px){
      font-size: 66px;
    }
    @media screen and (max-width: 720px){
      font-size: 46px;
    }
    span{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: calc(100% + 20px);
        bottom: 100%;
        background-color: $yellow;
        height: 38px;
        width: 38px;
        border-radius: 50%;
      }
    }
  }
  &__slider{
    margin-bottom: 70px;
    padding-bottom: 40px;
  }
  &__slide{
    padding: 0 15px;
  }
  &__item{
    background: #FFFFFF;
    box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
    border-radius: 27px;
    height: 370px;
    box-sizing: border-box;
    padding: 35px;
  }
  &__item-title{
    font-family: 'Mont', Arial;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 7px;
    color: $grey;
    transition: 0.3s all;
  }
  &__item-date{
    font-family: 'Inter', Arial;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    opacity: 0.5;
  }
  &__item-text{
    font-family: 'Inter', Arial;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    max-height: 210px;
    overflow: hidden;
    transition: 0.3s all;
  }
  &__form{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  &__form-close{
    position: absolute;
    right: 20px;
    top: 25px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    &:before,&:after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 2px;
      width: 100%;
      background-color: $grey;
      border-radius: 2px;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
  &__form-block{
    background: #FFFFFF;
    box-shadow: 0px 0px 31px rgba(59, 64, 240, 0.2);
    border-radius: 27px;
    padding: 40px 40px 100px;
    min-height: 67%;
    z-index: 1;
    width: 1170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }
  &__form-title{
    font-size: 24px;
    font-weight: 900;
    color: $grey;
    text-transform: uppercase;
  }
}
</style>
